/*
Hide the webpack-dev-server overlay (annoying resizeHandler error)
*/
iframe#webpack-dev-server-client-overlay{display:none!important}

.login-form-container {
  min-width: 400px;
  padding: 2rem !important;
}

@media (max-width: 576px) {
  .login-form-container {
    min-width: 100%;
  }
}

.paper {
    box-shadow: rgba(7, 6, 35, 0.063) 0px 0px 8px 8px;
    background: white;
    margin: 4px;
    padding: 16px;
    border-radius: 1rem;
  }
  
/* Upload image: list */
.upload-image-list-v2 .ant-upload-list-item-container {
  width: 150px !important;
  height: fit-content !important;
}

.upload-image-list-v2 .ant-upload-select {
  width: 150px !important;
  height: 150px !important;
}

/* Upload image: User */
.upload-user-image .ant-upload-list-item-container {
  width: 150px !important;
  height: 150px !important;
}

.upload-user-image .ant-upload-select {
  width: 150px !important;
  height: 150px !important;
  margin-inline-end: 0px !important;
}

.upload-user-image .ant-upload-list-item-thumbnail img {
  object-fit: cover !important;
}

/* Brute User Ranking Widget Antd List */
.brute-user-ranking-widget .ant-list-item {
  padding: 2px !important;
}
.brute-user-ranking-widget .ant-list-item-meta-title {
  margin: 0px !important;
}
.brute-user-ranking-widget .ant-list-pagination-align-end {
  margin: 2px !important;
}



/* color primary on hover */
.ant-tabs-tab:hover {
  fill: #0065ff !important;
}

.emoji-flag {
  font-family: "Twemoji Country Flags", serif;
}

.printed-table .ant-table table {
    font-size: 0.8rem;
}

.printed-table td {
    padding: 0.2rem !important;
}

.uplot .title {
    font-family: "Courier New";
    font-size: 22pt;
    color: blue;
  }
  
.uplot .legend {
font-family: "Courier New";
font-size: 22pt;
color: red;
}